import {useEffect, useState} from "react";
import { WebsiteProps } from "../components/HomePageProps"

export const useFetch = (url: string) => {
    const[state, setState] = useState<{data: WebsiteProps | null, loading:Boolean}>({data: null, loading: true});
    useEffect(() => {
        setState({data: null, loading: true});
        fetch(url)
            .then(x=>x.json())
            .then(y=>{
                setState({data: y, loading: false})
            })
    }, [url])

    return state;
}