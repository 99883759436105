import Box from '@mui/material/Box';
import { Container, Grid, Typography } from '@mui/material';
import waitingRoom from './../assets/waiting-room.jpg'
import { ReactElement, forwardRef } from 'react'; 
import { Main } from './HomePageProps';

interface WelcomeSectionProps   {
    data: Main | undefined,
    color: string,
    divider: ReactElement;
}

const WelcomeSection = forwardRef<HTMLDivElement | null, WelcomeSectionProps>(
    (
        props, ref
    ) => {
       
    return (
        <Box ref={ref}>
            <Box sx={{backgroundColor: props.color}}>
                <Container maxWidth={'lg'}>
                    <Grid container spacing={2} sx={{paddingTop: '35px'}}>
                        <Grid item  md={8} sm={6} order={{ xs: 2, sm: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    { props?.data !==undefined && <Typography align='left' variant='h4'>{props.data.title}</Typography> }
                                </Grid>
                                <Grid item xs={12}>
                                    { props?.data !==undefined && <Typography align='left'>{props.data.statement}</Typography>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={4} sm={6} order={{ xs: 1, sm: 2 }}>
                            <Box component='img' src={waitingRoom} sx={{width: "100%"}} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box sx={{backgroundColor: props.color}}>
                {props.divider}
            </Box>
        </Box>
    )});

export default WelcomeSection;