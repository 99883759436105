import Box from '@mui/material/Box';
import { DividerStyles } from './Divider.styles';
import { SxProps, useTheme } from '@mui/material/styles';


interface props {
    position?: string
}

const LightDivider = ({position}:{position: props})=> {

    const theme = useTheme();

    const style : SxProps = {
        'fill': theme.palette.lightDivider.main
    }

    const setPosition = position?.position === undefined ? "" : "absolute";

    return (<DividerStyles dividerPosition={setPosition} sx={style}>
    <Box>
        <svg width="100%" height="100" viewBox="0 0 100 102" preserveAspectRatio="none">
            <path d="M-10 0 L20 90 L110 0 V100 H0" />
        </svg>
    </Box>
    </DividerStyles>)
};

export default LightDivider;