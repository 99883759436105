import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';


const PrimaryGrey = '#f5f5f5';

const PrimaryWhite = '#ffffff';

declare module '@mui/material/styles' {
  interface PaletteOptions  {
    lightDivider: PaletteOptions ['primary'];
    darkDivider: PaletteOptions ['primary'];
  }
  interface Palette {
    lightDivider: Palette['primary'];
    darkDivider: Palette['primary'];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    lightDivider: {
      main: PrimaryWhite
    },
    darkDivider: {  
      main: PrimaryGrey
    }
  },
  typography:{
    fontFamily: 'Roboto'
  },
  components:{
    MuiLink: {
        defaultProps: {
          underline: 'none',
        },
        styleOverrides: {
          root: {
            fontWeight: 700,
            display: 'inline-flex',
            alignItems: 'center',
            '&.MuiTypography-body1 > svg': {
              marginTop: 2,
            },
            '& svg:last-child': {
              marginLeft: 2,
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides:{
          subtitle1: {
            fontSize: '.9rem',
            color: '#787878',
            fontStyle: 'italic',
            paddingTop: '10px',
            paddingLeft: '10px'
          }
        }
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: PrimaryGrey,
          },
          root:{
            color: 'black'
          }
        }
      }
    }
  }
);

export default theme;