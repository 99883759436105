import {
    AppBar,
    Toolbar,
    Button,
    IconButton,
    Link,
    MenuItem,
    Box,
    List,
    ListItem,
    Drawer,
  } from '@mui/material'
  import MenuIcon from '@mui/icons-material/Menu'
  import { useState, useEffect } from 'react';

  interface AppNavigationProps {
    headerData: HeaderData[],
    title: string | undefined,
    click: Function,
  }
  interface HeaderData {
    label: string,
    divRef: any
  }

 const AppNavigation = ({appProps} : {appProps: AppNavigationProps})=> {
  
    const [state, setState] = useState({
      mobileView: false,
      drawerOpen: false,
    });
  
    const { mobileView, drawerOpen } = state;

    const closeDrawer = () => {
      setState({...state, drawerOpen: false})
    }
  
    useEffect(() => {
      const setResponsiveness = () => {
        return window.innerWidth < 900
          ? setState((prevState) => ({ ...prevState, mobileView: true }))
          : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
      setResponsiveness();
  
      window.addEventListener('resize', () => setResponsiveness());
  
      return () => {
        window.removeEventListener('resize', () => setResponsiveness());
      };
    }, []);

    const displayDesktop = () => {
      return (
        <Toolbar sx={{   
          justifyContent: 'space-between'
          }}>
          {appProps.title}
          <Box>{getMenuButtons()}</Box>
        </Toolbar>
      );
    };
  
    const displayMobile = () => {
      const handleDrawerOpen = () =>
        setState((prevState) => ({ ...prevState, drawerOpen: true }));
      const handleDrawerClose = () =>
        setState((prevState) => ({ ...prevState, drawerOpen: false }));
  
      return (
        <Toolbar>
          <IconButton
            {...{
              edge: 'start',
              color: 'inherit',
              'aria-label': 'menu',
              'aria-haspopup': 'true',
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            {...{
              anchor: 'left',
              open: drawerOpen,
              onClose: handleDrawerClose,
              onOpen: handleDrawerOpen
            }}
          >
            <Box sx={{paddingRight: '40px'}}>{getDrawerChoices()}</Box>
          </Drawer>
          <Box>{appProps.title}</Box>
        </Toolbar>
      );
    };
  
    const getDrawerChoices = () => {
      return appProps.headerData.map(({ label, divRef }) => {
        return (
          <List>
            <ListItem>
              <Link
              {...{
                color: 'inherit',
                style: { textDecoration: 'none' },
                key: label
              }}
              >
                <MenuItem onClick={()=>{appProps.click(divRef);closeDrawer();}}>{label}</MenuItem>
              </Link>
            </ListItem>
          </List>
        );
      });
    };
  
    const getMenuButtons = () => {
      return appProps.headerData.map(({ label, divRef }) => {
        return (
          <Button
            {...{
              key: label,
              color: 'inherit'
            }}
            sx={{     
              fontWeight: 600,
              fontSize: '14px',
              marginLeft: '10px'}}
            onClick={()=>{appProps.click(divRef);}}
          >
            {label}
          </Button>
        );
      });
    };
  
    return (
      <header>
        <AppBar 
          color='primary'
          sx={{     
            paddingLeft: '10px',
            paddingRight: '10px',
            '@media (max-width: 900px)': {
              paddingLeft: 0,
            }
          }}>
          {mobileView ? displayMobile() : displayDesktop()}
        </AppBar>
      </header>
    );
  };
  export default AppNavigation;