import { SxProps } from '@mui/system';

export const ParentMainStyles: SxProps = {
    height: '100vh',
    position: 'relative',
    minHeight: '450px'
}

export const BackgroundImageStyles: SxProps = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    filter: 'brightness(50%)',
    backgroundPositionX: '70%',
    backgroundPositionY: '10%',
    backgroundAttachment: 'fixed',
    position: 'absolute',
    bottom: '0px',
    paddingBottom: '200px',
    zIndex: 1,
    width: '100%',
    height: '100%'
};

export const LogoParentStyles: SxProps = {
    position:'absolute',
    top: '80px',
    right: '20px',
    zIndex: '100'
}

export const ArrowHolderStyles: SxProps = {
    width:'350px', 
    paddingRight: '0', 
    maxHeight: '250px', 
    height:'25vh', 
    minHeight: '100px'
}