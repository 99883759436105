import Box from '@mui/material/Box';
import { Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import lobbyImage from './../assets/main-lobby.jpg'
import officeImage from './../assets/outside.jpg'
import { ReactElement, forwardRef } from 'react'; 
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import FaxIcon from '@mui/icons-material/Fax';
import { ContactInfo } from './HomePageProps';
import ContactUsForm from './ContactUsForm';

interface ContactSectionProps  {
    data: ContactInfo | undefined,
    color: string,
    divider: ReactElement;
}

const ContactSection = forwardRef<HTMLDivElement | null, ContactSectionProps>(
    (
        props, ref
    ) => {
    return (
        <Box ref={ref}>  
            <Box sx={{backgroundColor: props.color}}>
                <Container maxWidth={'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography align='left' variant='h4'>Contact Us</Typography>
                        </Grid>
                        <Grid item md={6} xs={12} order={{ xs: 3, md: 1}}>
                            <Paper sx={{height:'100%', width:'100%'}}>
                                <Box component='img' src={officeImage} sx={{width:'100%', height:'100%', objectFit:'cover'}} />
                            </Paper>
                        </Grid>
                        <Grid item md={6} xs={12} order={{ xs: 1, md: 2}}>
                            <Card>
                                <CardMedia
                                    component='iframe'
                                    src={props.data?.mapUrl}
                                    sx={{border:0}}
                                >
                                </CardMedia>
                                <CardContent>
                                    <List dense={true}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <PlaceIcon></PlaceIcon>
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={props.data?.addressLineOne +", "+props.data?.addressLineTwo}
                                                secondary={props.data?.city + ", " + props.data?.state + " " +props.data?.zip}
                                            >
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <PhoneIcon></PhoneIcon>
                                            </ListItemIcon>
                                            <ListItemText primary={props.data?.phone}>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FaxIcon></FaxIcon>
                                            </ListItemIcon>
                                            <ListItemText primary={props.data?.fax}>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </CardContent>
                                <CardActions>
                                    <Link href={'tel:'+props.data?.phone}><Button>Call us</Button></Link>
                                    <Link href={props.data?.openMapUrl} target='_blank'><Button>Open Google Maps</Button></Link>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item md={6} xs={12} order={{ xs: 2, md: 3}}>
                            <Paper sx={{padding:2, height:'100%'}}>
                                <ContactUsForm></ContactUsForm>
                            </Paper>
                        </Grid>
                        <Grid item md={6} xs={12} order={{ xs: 4 }}>
                            <Paper sx={{height:'100%', width:'100%'}}>
                                <Box component='img' src={lobbyImage} sx={{objectFit:'cover', width:'100%', height:'100%'}} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box sx={{backgroundColor: props.color}}>
                {props.divider}
            </Box>
        </Box>
    )})

export default ContactSection;