import { Contact } from '../components/ContactUsForm';

export type ContactApi = Contact & {
    'g-recaptcha-response': string
}

export const PostToServer = async (url: string, data: ContactApi) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }

    return await fetch(url, requestOptions);
}