import styles from '@emotion/styled';

interface BouncingArrowProps {
    partOnePosition: string;
    partTwoPosition: string;
    bounceRate: string;
}

export const BouncingObject = styles.div<BouncingArrowProps>`
    display: inline-block;
    position: relative;
    -moz-animation: bounce ${props => props.bounceRate}s infinite linear;
    -o-animation: bounce ${props => props.bounceRate}s infinite linear;
    -webkit-animation: bounce ${props => props.bounceRate}s infinite linear;
    animation: bounce ${props => props.bounceRate}s infinite linear;

    @-webkit-keyframes bounce {
        0% { top: 0; }
        50% { top: -${props => props.partOnePosition}em; }
        70% { top: -${props => props.partTwoPosition}em; }
        100% { top: 0; }
    }
    @-moz-keyframes bounce {
        0% { top: 0; }
        50% { top: -${props => props.partOnePosition}em; }
        70% { top: -${props => props.partTwoPosition}em; }
        100% { top: 0; }
    }
    @-o-keyframes bounce {
        0% { top: 0; }
        50% { top: -${props => props.partOnePosition}em; }
        70% { top: -${props => props.partTwoPosition}em; }
        100% { top: 0; }
    }
    @-ms-keyframes bounce {
        0% { top: 0; }
        50% { top: -${props => props.partOnePosition}em; }
        70% { top: -${props => props.partTwoPosition}em; }
        100% { top: 0; }
    }
    @keyframes bounce {
        0% { top: 0; }
        50% { top: -${props => props.partOnePosition}em; }
        70% { top: -${props => props.partTwoPosition}em; }
        100% { top: 0; }
    }
`;