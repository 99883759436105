import React from 'react';
import TagManager from 'react-gtm-module';
import './App.css';
import Home from './components/Home';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@emotion/react';
import theme from './theme';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const queryClient = new QueryClient()

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID === undefined ? '' : process.env.REACT_APP_GTM_ID,
  events: {
    contactSubmit: 'Contact Submit'
  }
}

function App() {
  TagManager.initialize(tagManagerArgs);
  
  return (
    <HelmetProvider>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY === undefined ? '' : process.env.REACT_APP_RECAPTCHA_KEY}
        language='en'
      >
        <QueryClientProvider client={queryClient}>
          <div className="App">
            <Helmet>
              <meta name="google-site-verification" content={process.env.REACT_APP_SITE_VERIFICATION} />
              <meta name="description" content="FundaMental Health - Owner Marisa Karcz Claysen" />
              <meta name="robots" content="index,follow" />
              <link rel="icon" href="/fundamental_health.ico"></link>
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <title>FundaMental Health - Marisa Karcz Claysen</title>
              <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"/>
            </Helmet>
            <ThemeProvider theme={theme}>
              <Home></Home>
            </ThemeProvider>
          </div>
        </QueryClientProvider>
      </GoogleReCaptchaProvider>
    </HelmetProvider>
  );
}
export default App;
