import Box from '@mui/material/Box';
import { Container, Grid, Typography } from '@mui/material';

const Footer = ({color}: {color: string}) => {
    return (
        <Box sx={{backgroundColor: color}}>
            <Container maxWidth={'lg'}>
                <Grid container spacing={2}>
                    <Grid item md={4} sm={6}>
                        <Box component='span'>
                            <Typography sx={{paddingBottom:'50px'}}>© Copyright 2023, FundaMental Health PLLC</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )};

export default Footer;