import Box from '@mui/material/Box';
import { Container, Grid, Typography } from '@mui/material';

import { Testimonials } from './HomePageProps';
import { ReactElement, forwardRef } from 'react'; 

interface TestimonailSectionProps   {
    data: Testimonials | undefined,
    color: string,
    divider: ReactElement;
}

const TestimonialSection = forwardRef<HTMLDivElement | null, TestimonailSectionProps>(
    (
        props, ref
    ) => {
    
        const testimonalLength = props.data !== undefined ? props.data?.details.length : 0;
        return (
        <Box ref={ref}>
            <Box sx={{backgroundColor: props.color}}>
                <Container maxWidth={'lg'}>
                    <Grid container spacing={2} rowSpacing={0}>
                        <Grid item xs={12}>
                            { props?.data !==undefined && <Typography align='left' variant='h4'>{props.data.title}</Typography> }
                        </Grid>
                        { props?.data !== undefined && props.data?.details.map((value, index)=>{
                            if(testimonalLength % 2!==0 && testimonalLength === index+1)
                            {
                                return (
                                    <Grid item md={12}>
                                        <Grid container>
                                            <Grid md={3} item />
                                            <Grid md={6} item>
                                                <Typography align='left' key={index}>{value.statement}</Typography>
                                                <Typography align='left' variant='subtitle1'>- {value.attribute}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            else
                                return (
                                    <Grid item md={6}>
                                        <Typography align='left' key={index}>{value.statement}</Typography>
                                        <Typography align='left' variant='subtitle1'>- {value.attribute}</Typography>
                                    </Grid>
                                )
                        })}
                    </Grid>
                </Container>
            </Box>
            <Box sx={{backgroundColor: props.color}}>
                {props.divider}
            </Box>
        </Box>
    )});

export default TestimonialSection;