import Box from '@mui/material/Box';
import { Container, Grid, Typography } from '@mui/material';
import providerImage from './../assets/marisa.jpg'
import { ReactElement, forwardRef } from 'react'; 
import { Main } from './HomePageProps';

interface ProviderInfoSectionProps  {
    data: Main | undefined,
    color: string,
    divider: ReactElement;
}

const ProviderInfoSection = forwardRef<HTMLDivElement | null, ProviderInfoSectionProps>(
    (
        props, ref
    ) => {
    return (
        <Box ref={ref}>
            <Box sx={{backgroundColor: props.color}}>
                <Container maxWidth={'lg'}>
                    <Grid container spacing={2}>
                        <Grid item md={4} sm={6}>
                            <Box component='img' src={providerImage} sx={{width: "100%"}} />
                        </Grid>
                        <Grid item md={8} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    { props?.data !==undefined && <Typography align='left' variant='h4'>{props.data.title}</Typography> }
                                </Grid>
                                <Grid item xs={12}>
                                    { props?.data !==undefined && <Typography align='left'>{props.data.statement}</Typography> }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box sx={{backgroundColor: props.color}}>
                {props.divider}
            </Box>
        </Box>
    )})

export default ProviderInfoSection;