import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from '@mui/material';
import { SxProps } from '@mui/system';
import { BouncingObject } from './BouncingArrow.style';

const Arrow = ({click} : {click  : any}) => {

    const arrowStyles: SxProps = {
        color: '#D0D0D0',
        position: 'absolute',
        width: '100px',
        maxHeight: '100px',
        height: '10vh',
        minHeight: '50px'
    };

    return (                      
        <Box className="float-right">
            <Link onClick={click}>
                <BouncingObject partOnePosition='.7' partTwoPosition='.9' bounceRate='1.5'>
                    <KeyboardArrowDownIcon sx={arrowStyles} ></KeyboardArrowDownIcon>
                </BouncingObject>
            </Link>
        </Box>
    )
};

export default Arrow;