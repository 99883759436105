import { useFormik } from 'formik';
import * as yup from 'yup'
import { Button, Grid, Typography } from '@mui/material';
import {TextField, Box} from '@mui/material';
import { CircularProgress } from '@mui/material';
import {
    useGoogleReCaptcha
  } from 'react-google-recaptcha-v3';
import _ from 'lodash';
import { useState } from 'react';
import { PostToServer, ContactApi } from '../api/PostToServer';
import TagManager from 'react-gtm-module';


const validationSchema = yup.object({
    firstName: yup
        .string()
        .required('Enter your first name'),
    lastName: yup
        .string()
        .required('Enter your last name'),
    emailAddress: yup
        .string()
        .email()
        .required("Enter your email address"),
    subject: yup
        .string()
        .required("Enter a subject for this message"),
    body: yup
        .string()
        .required("Enter why you are contacting us")
});

export type Contact = yup.InferType<typeof validationSchema>;

const initialContact: Contact = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    subject: "",
    body: ""
}

const ContactUsForm = () =>{

    const [postStatus, setPostStatus] = useState<{success: Boolean | null, loading: Boolean}>({success: null, loading: false});

    const postContactInfo = async (contact : Contact) => {

        const token = await handleReCaptchaVerify();

        if(_.isEmpty(contact) || token===undefined ||token === '') {
            return;
        }

        const contactApi: ContactApi = {...contact, 'g-recaptcha-response': token}

        TagManager.dataLayer({
            dataLayer: {
                event: 'contactSubmit',
                email: contact.emailAddress
            }
        });
        try
        {
            const result = await PostToServer(process.env.REACT_APP_API_URL+'/BAL/EmailHandler.php', contactApi);
            if(result.ok){
                setPostStatus({success: true, loading: false});
            }
            else
            {
                setPostStatus({success: false, loading: false});
            }
        }
        catch(error)
        {
            setPostStatus({success: false, loading: false});
        }
    };
    
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = async () => {
        
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        const token = await executeRecaptcha('contact_us');

        return token;
    };

    const formik = useFormik({
        initialValues: initialContact,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setPostStatus({success: null, loading: true});
            postContactInfo(values);
        }
    })

    if(postStatus.success === null)
    {
        return (
        <>
            <Typography variant='h5' sx={{textAlign: 'left'}}>Send us a message</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid item container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <TextField 
                            id="firstName"
                            name="firstName"
                            label="First name"
                            type="text"
                            variant="standard"
                            fullWidth={true}
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                        ></TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField 
                            id="lastName"
                            name="lastName"
                            label="Last name"
                            type="text"
                            variant="standard"
                            fullWidth={true}
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            id="emailAddress"
                            name="emailAddress"
                            label="Email address"
                            type="text"
                            variant="standard"
                            fullWidth={true}
                            value={formik.values.emailAddress}
                            onChange={formik.handleChange}
                            error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                            helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            id="subject"
                            name="subject"
                            label="Subject"
                            type="text"
                            variant="standard"
                            fullWidth={true}
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            error={formik.touched.subject && Boolean(formik.errors.subject)}
                            helperText={formik.touched.subject && formik.errors.subject}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            id="body"
                            multiline
                            name="body"
                            label="Message"
                            type="text"
                            variant="standard"
                            rows={4}
                            fullWidth={true}
                            value={formik.values.body}
                            onChange={formik.handleChange}
                            error={formik.touched.body && Boolean(formik.errors.body)}
                            helperText={formik.touched.body && formik.errors.body}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        {postStatus.loading === true && <CircularProgress />}
                        {postStatus.loading === false &&
                        <Box>
                            <Button 
                                color="primary" 
                                variant='contained' 
                                type='submit' 
                                fullWidth={true}>Submit
                            </Button>
                        </Box>}
                    </Grid>
                </Grid>
            </form>
        </>
    )}
    else
    {
        return (
            <>
                {postStatus.success === false && <Typography variant='h5' sx={{textAlign: 'left'}}>There was an error sending your message. Please try again later.</Typography>}
                {postStatus.success === true && <Typography variant='h5' sx={{textAlign: 'left'}}>Thank you for your message. We will be in contact with you.</Typography>}
            </>
        )
    }
}

export default ContactUsForm;