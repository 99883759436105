import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';

export const DividerStyles = styled(Box, {
    shouldForwardProp: (propName) => propName !== 'dark'
})

(({dividerPosition}: {dividerPosition : string})=>({
    bottom: dividerPosition === 'absolute' ? '-10px' : '0',
    width: '100%',
    zIndex: '99',
    strokeWidth: '4',
    position: dividerPosition === 'absolute' ? 'absolute' : 'relative'
}));
