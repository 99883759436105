import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import logo from './../assets/fundamental_health.svg'
import backgroundImage from './../assets/pebbles_comp.jpg'
import Arrow from './BouncingArrow';
import AppNavigation from './AppNavigation';
import { BackgroundImageStyles, LogoParentStyles, ArrowHolderStyles, ParentMainStyles } from './Home.styles';
import { useFetch } from '../api/useFetch';
import WelcomeSection from './WelcomeSection';
import LightDivider from './Divider/LightDivider';
import ProviderInfoSection from './ProviderInfoSection';
import ServicesOfferedSection from './ServicesOfferedSection';
import TestimonialSection from './TestimonialSection';
import { useRef, useEffect, useReducer, Reducer } from "react";
import ContactSection from './ContactSection';
import Footer from './Footer';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import DarkDivider from './Divider/DarkDivider';

interface State {
  ref: React.RefObject<HTMLDivElement>
}

interface Action {
  type: string,
  value: React.RefObject<HTMLDivElement> | undefined
}

const Home = () => {
  const scrollOffset = 70;

  const mainRef = useRef<HTMLDivElement>(null);
  const welcomeRef = useRef<HTMLDivElement>(null); 
  const providerRef = useRef<HTMLDivElement>(null);
  const serviceRef = useRef<HTMLDivElement>(null);
  const testimonailRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const initialState : State = {
    ref: mainRef
  }

  const ReducerFunction = (state: State, action: Action) => {
    if(action.type === "reset" || action.value === undefined)
      return initialState;
    
    const result: State = {...state}
    result.ref = action.value;
    return result;
  }
  
  const [state, dispatch] = useReducer<Reducer<State, Action>, State>(ReducerFunction, initialState, ()=> initialState);
  const headersData = [
    {
      label: 'About',
      divRef: welcomeRef,
    },
    {
      label: 'Provider Info',
      divRef: providerRef,
    },
    {
      label: 'Services',
      divRef: serviceRef,
    },
    {
      label: 'Testimonials',
      divRef: testimonailRef,
    },
    {
      label: 'Contact',
      divRef: contactRef
    }

  ];
  useEffect(()=>{
    if(state.ref.current !==null)
    {
      const yPosition = state.ref.current.getBoundingClientRect().top + window.scrollY - scrollOffset;
      window.scrollTo({top: yPosition, behavior: 'smooth'});
    }
  }, [state]);

  const handleClickEvent = (divRef: React.RefObject<HTMLDivElement>) => {
      dispatch({
        type: "scollEvent",
        value: divRef
      })
  }
  const scrollToAbout = () => {
    handleClickEvent(welcomeRef);
  }

  const {data, loading} = useFetch("../../data/homepage.json");

  const theme = useTheme();

  if(loading)
  {
    return(
      <Container maxWidth={'lg'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={25} sx={{marginBottom: '25px'}} />
            <Skeleton variant="rectangular" height={500} />
          </Grid>
        </Grid>
      </Container>
    )
  }
  else
  {
    return (
      <Box ref={mainRef}>
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
        <CssBaseline />
        <AppNavigation appProps={{headerData: headersData, title: data?.contactInfo.companyShortName, click: (href: any)=>{handleClickEvent(href)}}}></AppNavigation>
        <Box sx={ParentMainStyles}>
          <Box  sx=
              {{ 
                ...BackgroundImageStyles,
                backgroundImage: `url(${backgroundImage})`,
              }}>
          </Box>
          <Box sx={ LogoParentStyles }>
            <Box component='img' sx={ ArrowHolderStyles } src={logo} />
            <Arrow click={scrollToAbout}></Arrow>
          </Box>
          <LightDivider position={{position: "absoute"}}></LightDivider>
        </Box>
        <WelcomeSection 
          ref={welcomeRef} 
          data={data?.intro} 
          color={theme.palette.lightDivider.main} 
          divider={<DarkDivider position={{}}></DarkDivider>} />
        <ProviderInfoSection 
          ref={providerRef} 
          data={data?.provider} 
          color={theme.palette.darkDivider.main}
          divider={<LightDivider position={{}}></LightDivider>} />
        <ServicesOfferedSection 
          ref={serviceRef} 
          data={data?.services}
          color={theme.palette.lightDivider.main} 
          divider={<DarkDivider position={{}}></DarkDivider>} />
        <TestimonialSection 
          ref={testimonailRef} 
          data={data?.testimonials}
          color={theme.palette.darkDivider.main}
          divider={<LightDivider position={{}}></LightDivider>} />
        <ContactSection 
          ref={contactRef} 
          data={data?.contactInfo}
          color={theme.palette.lightDivider.main} 
          divider={<DarkDivider position={{}}></DarkDivider>} />
        <Footer
          color={theme.palette.darkDivider.main} />
      </Box>
    )
  }
}

export default Home;