import Box from '@mui/material/Box';
import { Container, Grid, Typography } from '@mui/material';
import officeLeaves from './../assets/office-leaves.jpg'
import { Services } from './HomePageProps';
import { ReactElement, forwardRef } from 'react'; 

interface ServicesOfferedSectionProps   {
    data: Services | undefined,
    color: string,
    divider: ReactElement;
}

const ServicesOfferedSection = forwardRef<HTMLDivElement | null, ServicesOfferedSectionProps>(
    (
        props, ref
    ) => {
    return (
    <Box ref={ref}>
        <Box sx={{backgroundColor: props.color}}>
            <Container maxWidth={'lg'}>
                <Grid container spacing={2}>
                    <Grid item md={8} sm={6} order={{ xs: 2, sm: 1 }}>
                        { props.data?.servicesOffered !== undefined && <Typography align='left' variant='h4'>{props.data.title}</Typography> }
                        { props.data?.servicesOffered !== undefined && props.data.servicesOffered.map((value, index)=>{
                            return (<Box><br /><Typography align='left'>{value}</Typography></Box>)
                        })}
                    </Grid>
                    <Grid item md={4} sm={6} order={{ xs: 1, sm: 2 }}>
                        <Box component='img' src={officeLeaves} sx={{width: "100%"}} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        { props.data?.insuranceInformation !== undefined && props.data.insuranceInformation.map((value, index)=>{
                            return (<Box><br /><Typography align='left'>{value}</Typography></Box>)
                        })}
                    </Grid>
                </Grid>
            </Container>
        </Box>
        <Box sx={{backgroundColor: props.color}}>
                {props.divider}
            </Box>
    </Box>
    )});

export default ServicesOfferedSection;